import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  Image,
  InputGroup,
  InputRightElement,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { Input } from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import { emailValidation } from "../../validations";
import { Link } from "gatsby";
import { useLocation } from "@reach/router";
import FooterLogo from "../../images/logo-footer.png";

const Footer = (props: any) => {
  let { pathname } = useLocation();

  const [email, setEmail] = React.useState("");
  //Handle Email Validation
  const [isEmailValid, setEmailValid] = React.useState(true);
  const handleEmail = (e: any) => {
    let email = e.target.value.trim();

    if (!email || emailValidation().test(email) === false) {
      setEmailValid(false);
    } else {
      setEmailValid(true);
      setEmail(email);
    }
  };
  const handleSubmit = () => {
    console.log("email", email);
  };

  return (
    <Flex
      backgroundColor="#cbb26a"
      color="#black"
      paddingY={{ base: 5, sm: 5, md: 10, lg: 20 }}
      paddingX={{ base: 10, sm: 10, md: 10, lg: 24 }}
      justify="center"
      flexDirection={{ base: "column", sm: "column", md: "row" }}
    >
      <Flex direction="column" color={"black"} gap={2.5} padding={2.5} flex={1}>
        <Box>
          <Box display={"flex"} alignItems={"center"}>
            <Link to="/">
              <Image src={FooterLogo} alt="logo" width={10} />
            </Link>
            <Text fontWeight={"semibold"} fontSize={30}>
              SyncStack
            </Text>
          </Box>
          <Text
            fontSize={{ base: "sm", sm: "sm", md: "md" }}
            fontWeight={"semibold"}
          >
            Where dreams meet creativity
          </Text>
        </Box>
        <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>
          Thane, Maharashtra
        </Text>
        <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>+919082213431</Text>
        <Text fontSize={{ base: "sm", sm: "sm", md: "md" }}>
          Thane, Maharashtra
        </Text>
      </Flex>
      <Divider
        display={{ base: "block", sm: "block", md: "none" }}
        marginY={5}
      />
      <Flex justify="space-between" flex={2}>
        <Flex direction="column" gap={2.5} padding={2.5} flex={1}>
          <Text
            fontSize={{ base: "md", sm: "md", lg: "2xl" }}
            color={"black"}
            fontWeight={600}
          >
            Important Links
          </Text>
          <Link to="/terms-of-service/">
            <Text
              fontWeight={pathname === "/terms-of-service/" ? 700 : 500}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              color={pathname === "/terms-of-service/" ? "white" : ""}
              className={
                pathname === "/terms-of-service/"
                  ? ""
                  : "hover-underline-animation"
              }
            >
              Terms of Service
            </Text>
          </Link>
          <Link to="/privacy-policy">
            <Text
              fontWeight={pathname === "/privacy-policy" ? 700 : 500}
              color={pathname === "/privacy-policy/" ? "white" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/privacy-policy/"
                  ? ""
                  : "hover-underline-animation"
              }
            >
              Privacy Policy
            </Text>
          </Link>
          <Link to="/refund-policy">
            <Text
              fontWeight={pathname === "/refund-policy/" ? 700 : 500}
              color={pathname === "/refund-policy/" ? "white" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/refund-policy/"
                  ? ""
                  : "hover-underline-animation"
              }
            >
              Refund Policy
            </Text>
          </Link>
          <Link to="/careers">
            <Text
              fontWeight={pathname === "/careers" ? 700 : 500}
              // color={pathname === "/careers" ? "primary.600" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/careers" ? "" : "hover-underline-animation"
              }
            >
              Careers
            </Text>
          </Link>
          {/* <Link to="/call">
            <Text
              fontWeight={pathname === "/call/" ? 700 : 500}
              color={pathname === "/call/" ? "primary.600" : ""}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
              className={
                pathname === "/call/" ? "" : "hover-underline-animation"
              }>
              Book a call
            </Text>
          </Link> */}
        </Flex>

        <Flex
          direction="column"
          gap={6}
          padding={2.5}
          justifyContent={"center"}
          flex={1}
        >
          <Text fontSize={{ base: "md", sm: "md", lg: "2xl" }} fontWeight={600}>
            Subscribe to our Newsletter
          </Text>
          <Box w={"100%"}>
            <Input
              placeholder="Enter your email"
              // onChange={(e) => {
              //   setFormData({
              //     ...formData,
              //     ListeningPoints: e.target.value.trim(),
              //   });
              // }}
              bgColor={"#ffe497"}
              borderRadius={20}
              border={"none"}
              color={"grey"}
              w={"100%"}
              type="email"
            />
          </Box>
          <Box
            w="100%"
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text
              borderRadius={30}
              p={2.5}
              bgColor={"#ffe497"}
              fontWeight={500}
              fontSize={{ base: "sm", sm: "sm", md: "md" }}
            >
              Submit
            </Text>
          </Box>
        </Flex>
      </Flex>
      {/* <Divider
        display={{ base: "block", sm: "block", md: "none" }}
        marginY={5}
      /> */}
      {/* <Flex direction="column" gap={5} padding={2.5} flex={1}>
        <Text fontSize={{ base: "md", sm: "md", lg: "2xl" }} fontWeight={600}>
          Stay up to date
        </Text>
        <FormControl
          width={{ base: "100%", sm: "fit-content", md: "fit-content" }}
        >
          <InputGroup>
            <Input
              type="email"
              placeholder="Email Address"
              background="#EFEFEF"
              color="#1a202c"
              _placeholder={{ color: "#24258280" }}
              onChange={handleEmail}
            />
            <InputRightElement cursor="pointer" onClick={handleSubmit}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z"
                  fill="#F64C72"
                />
              </svg>
            </InputRightElement>
          </InputGroup>
          {!isEmailValid && (
            <FormHelperText fontSize="12px" color="#FF3333">
              Enter a valid email address
            </FormHelperText>
          )}
        </FormControl>
      </Flex> */}
    </Flex>
  );
};

export default Footer;
