import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        fontFamily: "poppins",
      },
    },
  },
  colors: {
    primary: {
      400: "#cbb26a", // light
      500: "#fff", // primary
      600: "#000", //accent
    },
  },
  fonts: {
    heading: `'Poppins', sans-serif`,
    body: `'Poppins', sans-serif`,
  },
});

export default theme;
