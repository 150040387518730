import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  Image,
  Text,
  Container,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { HamburgerIcon } from "@chakra-ui/icons";
import MenuDrawer from "../Drawer";
import { useLocation } from "@reach/router";
import "./Header.css";
import Logo from "../../images/Logoo.png";

interface Props {
  children: React.ReactNode;
  path: string;
  pathname: string;
  scrolling: boolean;
}

const Links = ["Services", "About", "Contact"];

const NavLink = (props: Props) => {
  const { children, path, pathname, scrolling } = props;
  return (
    <Link to={`/${path.toLocaleLowerCase()}`}>
      <Box
        className={
          pathname === "/" && !scrolling
            ? "hover-underline-animation landing-page-nav"
            : pathname === `/${path.toLocaleLowerCase()}/`
            ? "underline-nav-item"
            : "hover-underline-animation"
        }
        textDecor={pathname === path ? "underline" : ""}
        textUnderlineOffset={3}
        fontSize="1rem"
        m="0 1rem"
        rounded="md"
        color={pathname === "/" && !scrolling ? "#fff" : "#cbb26a"}
        _hover={{
          textDecoration:
            pathname === `/${path.toLocaleLowerCase()}/` ? "underline" : "none",
          color: pathname === "/" && !scrolling ? "#fff" : "#fff",
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const Header = () => {
  // const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();

  // const handleClick = () => {
  //   onOpen();
  // };

  const [scrolling, setScrolling] = React.useState(false);

  React.useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 400) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <>
      <Box
        display={"flex"}
        transition={"background-color 0.5s ease"}
        position={"sticky"}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        bgColor={pathname == "/" && !scrolling ? "transparent" : "black"}
        color={"#cbb26a"}
        w={"100%"}
        top={0}
        zIndex={111}
        // h={14}
        px={20}
        py={4}
      >
        <Link to="/">
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Image src={Logo} alt="logo" width={8} />

            <Text color={"#cbb26a"} fontWeight={"semibold"} fontSize={20}>
              SyncStack
            </Text>
          </Box>
        </Link>
        <HStack
          // as={"nav"}
          // spacing={4}
          fontWeight={500}
          // height="100%"s
          display={{ base: "none", md: "flex" }}
        >
          {Links.map((link) => (
            <NavLink
              key={link}
              path={link}
              pathname={pathname}
              scrolling={scrolling}
            >
              {link}
            </NavLink>
          ))}
        </HStack>
        <Link to="/events">
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            w={20}
            transition={"0.2s ease"}
            p={2}
            cursor={"pointer"}
            _hover={{
              boxShadow: "2px 2px 0px 0px var(--chakra-colors-primary-500)",
            }}
            borderRadius={30}
            bgColor={"#cbb26a"}
          >
            <Text color={"white"} fontSize={13}>
              Events
            </Text>
          </Box>
        </Link>
      </Box>
    </>
  );
};
export default Header;
